import { defineStore } from 'pinia';
import type { Database } from '~/types/api/supabase.types';
import handleFrontendError from '../utils/handleFrontendError';
import { convertObjectKeysToCamelCase } from '~/utils/formatters';
import { computed } from 'vue';
import type { HeadingSortState } from '~/types/app.types';

export const useUserStore = defineStore('User', () => {
	const supabase = useSupabaseClient<Database>();
	const supabaseUser = convertObjectKeysToCamelCase(useSupabaseUser().value);

	const userFirstName = computed(() => {
		if (supabaseUser?.userMetadata) {
			return supabaseUser.userMetadata.firstName;
		}
		return null;
	});

	const userLastName = computed(() => {
		if (supabaseUser?.userMetadata) {
			return supabaseUser.userMetadata.lastName;
		}
		return null;
	});

	const userRole = computed(() => {
		if (supabaseUser?.appMetadata) {
			return supabaseUser.appMetadata.role;
		}
		return null;
	});

	const authenticated = computed(() => {
		if (supabaseUser?.aud && supabaseUser.aud === 'authenticated') {
			return true;
		}
		return false;
	});

	// /dashboard/products filters
	const productsFilterSearchTerm = ref('');
	const productsFilterCategory = ref('All Categories');
	const productsFilterPriceTier = ref('All Price Tiers');
	const productsFilterOnlyShowInStock = ref(true);
	const productsCurrentPage = ref(1);
	const sortByFeatured = ref<HeadingSortState>('inactive');
	const sortByLean = ref<HeadingSortState>('inactive');
	const sortByPricingTier = ref<HeadingSortState>('inactive');
	const sortByProductName = ref<HeadingSortState>('inactive');
	const sortByStockUnits = ref<HeadingSortState>('inactive');

	/**
	 * Watch for user filter selection and reset page to 1.
	 * This is to avoid empty pages when the user is on a page other than #1 and the returned
	 * filteredProducts contains fewer elements than the specified itemsPerPage.
	 */
	watch([productsFilterSearchTerm, productsFilterCategory, productsFilterPriceTier], () => {
		// If any filter changes, reset to the first page
		productsCurrentPage.value = 1;
	});

	function resetProductsFilters() {
		productsFilterSearchTerm.value = '';
		productsFilterCategory.value = 'All Categories';
		productsFilterPriceTier.value = 'All Price Tiers';
		productsFilterOnlyShowInStock.value = false;
		productsCurrentPage.value = 1;
		sortByFeatured.value = 'inactive';
		sortByLean.value = 'inactive';
		sortByPricingTier.value = 'inactive';
		sortByProductName.value = 'inactive';
		sortByStockUnits.value = 'inactive';
	}

	const showClearFiltersButton = computed(() => {
		return (
			productsFilterSearchTerm.value !== '' ||
			productsFilterCategory.value !== 'All Categories' ||
			productsFilterPriceTier.value !== 'All Price Tiers' ||
			productsFilterOnlyShowInStock.value !== false ||
			productsCurrentPage.value !== 1 ||
			sortByLean.value !== 'inactive' ||
			sortByPricingTier.value !== 'inactive' ||
			sortByProductName.value !== 'inactive' ||
			sortByStockUnits.value !== 'inactive'
		);
	});

	async function logout() {
		try {
			const { error } = await supabase.auth.signOut();

			if (error) throw error;

			return navigateTo('/dashboard/login');
		} catch (error) {
			handleFrontendError(error);
		}
	}

	return {
		userFirstName,
		userLastName,
		userRole,
		authenticated,
		supabase,
		logout,
		productsFilterSearchTerm,
		productsFilterCategory,
		productsFilterPriceTier,
		productsFilterOnlyShowInStock,
		productsCurrentPage,
		resetProductsFilters,
		showClearFiltersButton,
		sortByFeatured,
		sortByLean,
		sortByPricingTier,
		sortByProductName,
		sortByStockUnits,
	};
});
